import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";

import "./index.css";

const App = lazy(() => import("./App"));
const Navbar = lazy(() => import("./Components/Navbar/Navbar"));
const Footer = lazy(() => import("./Components/Footer/Footer"));
const Cookies = lazy(() => import("./pages/Cookies/Cookies"));
const ToS = lazy(() => import("./pages/ToS/ToS"));

const Portfolio = lazy(() => import("./pages/Portfolio/Portfolio"));
const PortfolioDetail = lazy(() =>
  import("./pages/PortfolioDetail/PortofolioDetail")
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<div>Se incarcă...</div>}>
        <Navbar />
        <Routes>
          <Route element={<App />} path="/" />
          <Route element={<Portfolio />} path="/portofoliu" />
          <Route path="/portofoliu/:slug" element={<PortfolioDetail />} />
          <Route element={<Cookies />} path="/cookies" />
          <Route element={<ToS />} path="/termeni-si-conditii" />
        </Routes>
        <Footer />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
